body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
Date: 24 Fev 2015
Author: Pedro Oliveira <kanytu@gmail . com>
*/

.hljs {
  color: #a9b7c6;
  background: #282b2e;
}

.hljs-number,
.hljs-literal,
.hljs-symbol,
.hljs-bullet {
  color: #6897BB;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-deletion {
  color: #cc7832;
}

.hljs-variable,
.hljs-template-variable,
.hljs-link {
  color: #629755;
}

.hljs-comment,
.hljs-quote {
  color: #808080;
}

.hljs-meta {
  color: #bbb529;
}

.hljs-string,
.hljs-attribute,
.hljs-addition {
  color: #6A8759;
}

.hljs-section,
.hljs-title,
.hljs-type {
  color: #ffc66d;
}

.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #e8bf6a;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}